import { ChangeEvent, useState } from 'react'
import s from './CartItem.module.scss'
import { useUI } from '@components/ui/context'
import { Trash } from '@components/icons'
import { LineItem } from '@common/types/cart'
import { Quantity, Link, Image } from '@components/ui'
import usePrice from '@common/product/use-price'
import useRemoveItem from '@framework/cart/use-remove-item'
import useUpdateItem from '@framework/cart/use-update-item'

const CartItem = ({
  item,
  currencyCode,
  setItemsOutOfStock,
}: {
  item: LineItem
  currencyCode: string
  setItemsOutOfStock: (boolean: boolean) => void
}) => {
  const { closeSidebarIfPresent } = useUI()
  const [quantity, setQuantity] = useState<number>(item.quantity)
  // TODO: Add in Removing/Loading Handling
  const [removing, setRemoving] = useState(false)
  const removeItem = useRemoveItem()
  const updateItem = useUpdateItem()
  const { price } = usePrice({
    amount: item.variant.price! * item.quantity,
    baseAmount: item.variant.listPrice! * item.quantity,
    currencyCode,
  })

  const handleChange = async ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    setQuantity(Number(value))

    await updateItem({ ...item, ...{ quantity: Number(value) } })
  }

  const increaseQuantity = async (n = 1) => {
    const val = Number(quantity) + n
    setQuantity(val)

    await updateItem({ ...item, ...{ quantity: val } })
  }

  const handleRemove = async () => {
    setRemoving(true)
    try {
      await removeItem(item)
    } catch (error) {
      setRemoving(false)
      throw error
    }
  }

  // TODO: look into Vercel's useEffect hook, it's currently broken with our build but may help keep state
  const isActive = item.availableForSale
  if (!isActive && setItemsOutOfStock) setItemsOutOfStock(true)

  return (
    <>
      <li className={s.root}>
        <div className={s['image-wrapper']}>
          <Link href={`/products/${item.path}`}>
            <Image
              onClick={() => closeSidebarIfPresent()}
              src={item.variant.image!.url}
              layout="fill"
              objectFit="scale-down"
              /**
               * This should be the same size as the sizes in the ProductViewMedia
               * component, to prevent loading unneccesary images.
               */
              sizes="480px"
            />
          </Link>
        </div>
        <div className={s['info-1']}>
          <div className={s['name-wrapper']}>
            <Link href={`/products/${item.path}`} className={s.name}>
              <span onClick={() => closeSidebarIfPresent()}>{item.name}</span>
              {!isActive && (
                <div className={s['out-of-stock']}>No Longer Available</div>
              )}
            </Link>
            <button className={s.remove} onClick={handleRemove}>
              <Trash width={20} height={20} />
            </button>
          </div>
          {item.options && item.options.length > 0 && (
            <div className={s.options}>
              {item.options.map((option) => (
                <div key={`${item.id}-${option.name}`} className={s.option}>
                  <span className={s['option-name']}>{option.name}:</span>
                  <span className={s['option-value']}>{option.value}</span>
                </div>
              ))}
            </div>
          )}
          <div className={s['qty-price-wrap']}>
            <div className={s.price}>
              <span>{price}</span>
            </div>
            <Quantity
              value={quantity}
              handleChange={handleChange}
              increase={() => increaseQuantity(1)}
              decrease={() => increaseQuantity(-1)}
              max={item.quantityAvailable}
            />
          </div>
        </div>
      </li>
    </>
  )
}

export default CartItem
