import React, { FC, CSSProperties } from 'react'
import { useIsIframe } from '@hooks'

export type LinkProps = {
  href: string
  target?: string
  className?: string
  style?: CSSProperties
}

const ExternalLink: FC<React.PropsWithChildren<LinkProps>> = ({
  href,
  className,
  target = '_self',
  children,
  style,
}) => {
  const isIframe = useIsIframe()

  const onClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    if (isIframe) {
      event.preventDefault()

      window.parent.location.href = href
    }
  }

  const rel = target === '_blank' ? 'noopener noreferrer' : ''

  return (
    <a
      href={href}
      className={className}
      target={target}
      onClick={onClick}
      rel={rel}
      style={style}
    >
      {children}
    </a>
  )
}

export default ExternalLink
