import { FC, useEffect, useRef } from 'react'
import s from './Sidebar.module.scss'
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import { useDispatchIframePostMessage } from '@hooks'

interface Props {
  children: any
  onClose: () => void
}

const Sidebar: FC<Props> = ({ onClose, children }) => {
  const sidebarRef = useRef() as React.MutableRefObject<HTMLDivElement>
  const contentRef = useRef() as React.MutableRefObject<HTMLDivElement>
  const [dispatch] = useDispatchIframePostMessage()

  const onKeyDownSidebar = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.code === 'Escape') {
      onClose()
    }
  }

  useEffect(() => {
    if (sidebarRef.current) {
      sidebarRef.current.focus()
    }

    const contentElement = contentRef.current

    if (contentElement) {
      disableBodyScroll(contentElement, { reserveScrollBarGap: true })
    }

    dispatch({
      event: 'setSidebarOpen',
      payload: true,
    })

    return () => {
      clearAllBodyScrollLocks()

      dispatch({
        event: 'setSidebarOpen',
        payload: false,
      })
    }
  }, [])

  return (
    <div
      className={s.root}
      ref={sidebarRef}
      onKeyDown={onKeyDownSidebar}
      tabIndex={1}
    >
      <div>
        <div className={s.backdrop} onClick={onClose} />
        <section className={s['sidebar-wrapper']}>
          <div className={s.sidebar} ref={contentRef}>
            {children}
          </div>
        </section>
      </div>
    </div>
  )
}

export default Sidebar
