import { FC, memo } from 'react'
import cn from 'classnames'
import s from './Searchbar.module.scss'
import { useRouter } from 'next/router'
import Search from '@components/icons/Search'
import { useIsIframe } from '@hooks'

interface Props {
  className?: string
  id?: string
}

const Searchbar: FC<Props> = ({ className, id = 'search' }) => {
  const router = useRouter()
  const isIframe = useIsIframe()
  const { query } = router.query

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const target = e.target as HTMLFormElement
    const q = target.searchquery.value

    if (isIframe) {
      window.parent.location.href = `/search/${q}`
    } else {
      window.parent.location.href = `/search/${q}`
      /*router.push(
        {
          pathname: '/search/[query]',
          query: { query: q },
        },
        undefined,
        { shallow: true }
      )*/
    }
  }

  return (
    <form onSubmit={handleFormSubmit} className={cn(s.root, className)}>
      <label className={s.label} htmlFor={id}>
        Search
      </label>
      <button className={s.button} type="submit">
        <Search />
      </button>
      <input
        id={id}
        className={s.input}
        name="searchquery"
        placeholder="Search for products..."
        defaultValue={query}
      />
    </form>
  )
}

export default memo(Searchbar)
