import { useEffect, useState } from 'react'

const useMedia = <T>(queries: string[], values: T[], defaultValue: T) => {
  const [value, setValue] = useState<T>(defaultValue)

  useEffect(() => {
    let mediaQueryLists = [] as any

    const getValue = () => {
      // Get index of first media query that matches
      const index = mediaQueryLists.findIndex((mql: any) => mql.matches)
      // Return related value or defaultValue if none
      return values?.[index] || defaultValue
    }

    const handler = () => setValue(getValue)

    if (process.browser) {
      mediaQueryLists = queries.map((q) => window.matchMedia(q))
    }

    // Reset state
    handler()

    mediaQueryLists.forEach((mql: any) => mql.addListener(handler))

    return () => {
      mediaQueryLists.forEach((mql: any) => mql.removeListener(handler))
    }
  })

  return value
}

export default useMedia
