import { FC } from 'react'
import s from './Grid.module.scss'
import cn from 'classnames'

interface Props {
  children: React.ReactNode | React.ReactNode[]
  xs?: number
  sm?: number
  md?: number
  lg?: number
  xl?: number
  xxl?: number
  xsOrder?: number
  smOrder?: number
  mdOrder?: number
  lgOrder?: number
  xlOrder?: number
  xxlOrder?: number
  embla?: boolean
}

const Column: FC<Props> = ({
  children,
  xs = 12,
  sm,
  md,
  lg,
  xl,
  xxl,
  xsOrder,
  smOrder,
  mdOrder,
  lgOrder,
  xlOrder,
  xxlOrder,
  embla = false,
}) => {
  return (
    <div
      className={cn(
        s[`col-xs-${xs}`],
        s[`col-sm-${sm}`],
        s[`col-md-${md}`],
        s[`col-lg-${lg}`],
        s[`col-xl-${xl}`],
        s[`col-xxl-${xxl}`],
        s[`order-xs-${xsOrder}`],
        s[`order-sm-${smOrder}`],
        s[`order-md-${mdOrder}`],
        s[`order-lg-${lgOrder}`],
        s[`order-xl-${xlOrder}`],
        s[`order-xxl-${xxlOrder}`],
        embla ? s['embla-col'] : ''
      )}
    >
      {children}
    </div>
  )
}

export default Column
