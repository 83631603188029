import { FC, useEffect } from 'react'
import { useRouter } from 'next/router'
import CookieConsent, { getCookieConsentValue } from 'react-cookie-consent'
import { Footer, Navbar } from '@components/common'
import {
  Sidebar,
  Checklist,
  Discount,
  Competitor,
  Unadvertised,
  ChecklistSuccess,
  ExternalLink,
} from '@components/ui'
import { CartSidebar } from '@components/cart'
import { useUI } from '@components/ui/context'
import s from './Layout.module.scss'
import ScrollToTop from '../ScrollToTop'

const Layout: FC = ({ children }) => {
  const { pathname } = useRouter()
  const {
    displaySidebar,
    closeSidebar,
    displayChecklist,
    closeChecklist,
    displayDiscount,
    closeDiscount,
    displayCompetitor,
    closeCompetitor,
    displayUnadvertised,
    closeUnadvertised,
    displayChecklistSuccess,
    closeChecklistSuccess,
  } = useUI()

  let displayHeader = true
  let displayContent = true
  let displayFooter = true

  if (pathname === '/static_header') {
    displayContent = false
    displayFooter = false
  }

  if (pathname === '/static_footer') {
    displayContent = false
    displayHeader = false
  }

  const enableCookies = () => {
    gtag('consent', 'update', {
      ad_storage: 'granted',
      analytics_storage: 'granted',
    })
  }

  const disableCookies = () => {
    gtag('consent', 'update', {
      ad_storage: 'denied',
      analytics_storage: 'denied',
    })
  }

  const cookieConsentOnAccept = () => {
    enableCookies()
  }

  const cookieConsentOnDecline = () => {
    disableCookies()
  }

  useEffect(() => {
    const cookieConsent = getCookieConsentValue()

    if (cookieConsent) {
      enableCookies()
    } else {
      disableCookies()
    }
  }, [])

  return (
    <>
      {displayHeader && <Navbar />}
      {displayHeader && displaySidebar && (
        <Sidebar onClose={closeSidebar}>
          <CartSidebar />
        </Sidebar>
      )}
      {displayHeader && displayChecklist && (
        <Sidebar onClose={closeChecklist}>
          <Checklist />
        </Sidebar>
      )}
      {displayHeader && displayDiscount && (
        <Sidebar onClose={closeDiscount}>
          <Discount />
        </Sidebar>
      )}
      {displayHeader && displayCompetitor && (
        <Sidebar onClose={closeCompetitor}>
          <Competitor />
        </Sidebar>
      )}
      {displayHeader && displayUnadvertised && (
        <Sidebar onClose={closeUnadvertised}>
          <Unadvertised />
        </Sidebar>
      )}
      {displayHeader && displayChecklistSuccess && (
        <Sidebar onClose={closeChecklistSuccess}>
          <ChecklistSuccess />
        </Sidebar>
      )}
      <main id="layoutMain" className={s.root}>
        {displayContent && children}
      </main>
      {displayContent && (
        <CookieConsent
          enableDeclineButton
          onAccept={cookieConsentOnAccept}
          onDecline={cookieConsentOnDecline}
          extraCookieOptions={
            process.env.NEXT_PUBLIC_DEPLOYMENT === 'development'
              ? {}
              : {
                  domain: '.tspoolsupply.com',
                }
          }
        >
          This website uses cookies and similar technologies to enhance the user
          experience. &nbsp;
          <ExternalLink
            href={'/about/privacy-policy/'}
            style={{ color: 'inherit' }}
          >
            Privacy Policy
          </ExternalLink>
        </CookieConsent>
      )}
      {displayFooter && <Footer />}
      {displayContent && <ScrollToTop />}
    </>
  )
}

export default Layout
