interface Message {
  event: string
  payload: any
}

// TODO: Add Return Typing
const useDispatchIframePostMessage = (): any => {
  const dispatch = (message: Message) => {
    if (process.browser && window.location !== window.parent.location) {
      window.parent.postMessage(
        JSON.stringify(message),
        'https://shop.tspoolsupply.com'
      )
    }
  }

  return [dispatch]
}

export default useDispatchIframePostMessage
