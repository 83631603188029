import { FC } from 'react'
import s from './Grid.module.scss'
import cn from 'classnames'

interface Props {
  children: React.ReactNode | React.ReactNode[]
  xsGutter?: number
  smGutter?: number
  mdGutter?: number
  lgGutter?: number
  xlGutter?: number
  xxlGutter?: number
  wrap?: 'wrap' | 'nowrap' | 'reverse'
}

const Grid: FC<Props> = ({
  children,
  xsGutter,
  smGutter,
  mdGutter,
  lgGutter,
  xlGutter,
  xxlGutter,
  wrap = 'wrap',
}) => {
  return (
    <div
      className={cn(
        s.root,
        s[`gutter-xs-${xsGutter}`],
        s[`gutter-sm-${smGutter}`],
        s[`gutter-md-${mdGutter}`],
        s[`gutter-lg-${lgGutter}`],
        s[`gutter-xl-${xlGutter}`],
        s[`gutter-xxl-${xxlGutter}`],
        s[wrap]
      )}
    >
      {children}
    </div>
  )
}

export default Grid
