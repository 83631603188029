import { useState } from 'react'

const useIsIframe = (): boolean => {
  const [isIframe, setIsIframe] = useState(
    process.browser && window.location !== window.parent.location
  )

  return isIframe
}

export default useIsIframe
