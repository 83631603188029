import React, { FC } from 'react'
import NextLink, { LinkProps as NextLinkProps } from 'next/link'
import { useIsIframe } from '@hooks'

export type LinkProps = NextLinkProps & {
  className?: string
}

const Link: FC<LinkProps> = ({
  href,
  prefetch,
  replace,
  scroll,
  shallow,
  locale,
  className,
  children,
}) => {
  const isIframe = useIsIframe()

  const onClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    if (isIframe) {
      event.preventDefault()

      window.parent.location.href = href as string
    }
  }

  return (
    <NextLink
      href={href}
      prefetch={prefetch}
      replace={replace}
      scroll={scroll}
      shallow={shallow}
      locale={locale}
    >
      <a onClick={onClick} className={className}>
        {children}
      </a>
    </NextLink>
  )
}

export default Link
