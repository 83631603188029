import { FC, useEffect, useRef } from 'react'
import s from './ChecklistSuccess.module.scss'
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import { useDispatchIframePostMessage } from '@hooks'
import { Cross } from '@components/icons'
import { useUI } from '@components/ui/context'

const ChecklistSuccess: FC = () => {
  const checklistSuccessRef = useRef() as React.MutableRefObject<HTMLDivElement>
  const contentRef = useRef() as React.MutableRefObject<HTMLDivElement>
  const [dispatch] = useDispatchIframePostMessage()
  const { closeChecklistSuccess } = useUI()
  const { openChecklistSuccess } = useUI()

  const onKeyDownChecklistSuccess = (
    event: React.KeyboardEvent<HTMLDivElement>
  ) => {
    if (event.code === 'Escape') {
      closeChecklistSuccess()
    }
  }

  useEffect(() => {
    if (checklistSuccessRef.current) {
      checklistSuccessRef.current.focus()
    }

    const contentElement = contentRef.current

    if (contentElement) {
      disableBodyScroll(contentElement, { reserveScrollBarGap: true })
    }

    dispatch({
      event: 'setChecklistSuccessOpen',
      payload: true,
    })

    return () => {
      clearAllBodyScrollLocks()

      dispatch({
        event: 'setChecklistSuccessOpen',
        payload: false,
      })
    }
  }, [])

  return (
    <>
      <div
        className={s.root}
        ref={checklistSuccessRef}
        onKeyDown={onKeyDownChecklistSuccess}
        tabIndex={1}
      >
        <div>
          <div className={s.backdrop} onClick={closeChecklistSuccess} />
          <section className={s['sidebar-wrapper']}>
            <div className={s.sidebar}>
              <header className={s.header}>
                <h2 className={s.title}>THANK YOU</h2>
                <button onClick={closeChecklistSuccess} className={s.close}>
                  <Cross />
                </button>
              </header>
              <div className={s.wrapper}>
                <div className={s.noitems}>
                  Thank you for your request! We will email you back soon with
                  details.
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  )
}

export default ChecklistSuccess
