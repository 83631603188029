const Bag = ({ ...props }) => {
  return (
    <svg
      {...props}
      width="23"
      height="20"
      viewBox="0 0 23 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.818401 0.000460098C-0.153329 -0.0291399 -0.316269 1.37865 0.636511 1.5719L2.39185 1.95639C3.32038 6.39827 4.33253 10.822 5.31402 15.2528C5.39472 15.6161 5.71699 15.8746 6.08912 15.8745H20.028C21.1019 15.8904 21.1019 14.2712 20.028 14.287H6.72625L6.37487 12.7H20.7313C21.1034 12.7001 21.4256 12.4416 21.5064 12.0783L23.2643 4.14176C23.3739 3.64638 22.997 3.17675 22.4897 3.17646C16.4166 3.17659 10.3436 3.17633 4.27074 3.17346L3.83927 1.12638C3.77497 0.820856 3.53755 0.581439 3.23263 0.514529L0.975541 0.0204796C0.923941 0.00857963 0.871311 0.0017601 0.818401 0.000460098ZM9.39306 16.4042C8.23353 16.4042 7.27652 17.3613 7.27652 18.5209C7.27652 19.6805 8.23353 20.6376 9.39306 20.6376C10.5526 20.6376 11.5096 19.6805 11.5096 18.5209C11.5096 17.3613 10.5526 16.4042 9.39306 16.4042ZM16.8009 16.4042C15.6414 16.4042 14.6844 17.3613 14.6844 18.5209C14.6844 19.6805 15.6414 20.6376 16.8009 20.6376C17.9605 20.6376 18.9175 19.6805 18.9175 18.5209C18.9175 17.3613 17.9605 16.4042 16.8009 16.4042Z"
        fill="#1A61A8"
      />
    </svg>
  )
}

export default Bag
