import React, { useState, useRef, useEffect } from 'react'
import cn from 'classnames'
import { useOnClickOutside } from '@hooks'
import DownArrow from '@components/icons/DownArrow'
import s from './Select.module.scss'

// TODO Look into better type handling that matches input values:
// https://stackoverflow.com/questions/66738941/how-to-type-a-custom-react-select-component-using-typescript
type Value = string | number

type Option = {
  value: Value
  label: string
}

type Props = {
  value: Value
  onChange: (newValue: Value) => void
  options: Option[]
  placeholder?: string
}

const Select: React.FC<Props> = ({ value, onChange, options, placeholder }) => {
  const [selectedOption, setSelectedOption] = useState<Option>({} as Option)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const selectRef = useRef<HTMLDivElement>(null)
  const selectPlaceholder = placeholder || 'Choose an option'
  const dropdownHandler = () => setIsOpen(!isOpen)
  const handleClick = (option: Option) => {
    dropdownHandler()
    onChange(option.value)
  }

  useOnClickOutside(selectRef, () => {
    setIsOpen(false)
  })

  useEffect(() => {
    setSelectedOption(
      options.find((option) => {
        return value === option.value
      }) ?? ({} as Option)
    )
  }, [options, value])

  return (
    <div className={s.root} ref={selectRef}>
      <div className={s.control} onClick={dropdownHandler}>
        {Object.keys(selectedOption).length
          ? selectedOption.label
          : selectPlaceholder}
      </div>
      <DownArrow
        className={cn({
          [s.arrow]: true,
          [s.open]: isOpen,
        })}
      />
      <ul
        className={cn({
          [s.dropdown]: true,
          [s.open]: isOpen,
        })}
      >
        {options.map((option) => (
          <li
            key={option.value}
            className={cn({
              [s.selected]: value === option.value,
              [s['dropdown-item']]: true,
            })}
            onClick={() => {
              handleClick(option)
            }}
          >
            {option.label}
          </li>
        ))}
      </ul>
    </div>
  )
}

export default Select
