import { FC } from 'react'
import s from './Footer.module.scss'
import {
  Envelope,
  Hashtag,
  Phone,
  Address,
  Facebook,
  Google,
} from '@components/icons'
import { Grid, Column } from '@components/common'
import { Link, ExternalLink, Image } from '@components/ui'

const FooterLinks = ({ links }: any) => {
  return links.map((link: { href: string; text: string }) => (
    <ExternalLink key={link.text} href={link.href} className={s['nav-link']}>
      {link.text}
    </ExternalLink>
  ))
}

const Footer: FC = () => {
  const links = [
    {
      text: 'EXCLUSIVE PRICING',
      href: 'https://shop.tspoolsupply.com/pages/save-with-price-match',
    },
    {
      text: 'FAQ',
      href: 'https://shop.tspoolsupply.com/pages/faq',
    },
    {
      text: 'PRIVACY POLICY',
      href: '/about/privacy-policy/',
    },
    {
      text: 'TERMS & CONDITIONS',
      href: '/about/terms-and-conditions/',
    },
    {
      text: 'SHIPPING POLICY',
      href: '/about/shipping-policy/',
    },
    {
      text: 'RETURN & REFUND POLICY',
      href: '/about/return-refund-policy/',
    },
  ]

  return (
    <footer className={s.root}>
      <section className={s['top-footer']}>
        <Grid mdGutter={15}>
          <Column sm={12} md={2}>
            <div className={`${s['col']} ${s['img-col']}`}>
              <Link href="/" className={s['logo-link']}>
                <div className={s['logo-wrapper']}>
                  <Image
                    layout="fill"
                    objectFit="scale-down"
                    objectPosition="center center"
                    src="/static_images/ts-pool-supply-logo-blue-shasta.svg"
                    alt="TS Pool Supply"
                    unoptimized={true}
                  />
                </div>
              </Link>
              <div className={s.shopperapproved}>
                <a href="https://www.shopperapproved.com/reviews/tspoolsupply.com" className="shopperlink new-sa-seals placement-default"><img src="https://www.shopperapproved.com/seal/39770/default-sa-seal.gif" alt="Customer Reviews" /></a>
                <script dangerouslySetInnerHTML={{
                  __html: `(function() { var js = window.document.createElement("script"); js.innerHTML = 'function openshopperapproved(o){ var e="Microsoft Internet Explorer"!=navigator.appName?"yes":"no",n=screen.availHeight-90,r=940;return window.innerWidth<1400&&(r=620),window.open(this.href,"shopperapproved","location="+e+",scrollbars=yes,width="+r+",height="+n+",menubar=no,toolbar=no"),o.stopPropagation&&o.stopPropagation(),!1}!function(){for(var o=document.getElementsByClassName("shopperlink"),e=0,n=o.length;e<n;e++)o[e].onclick=openshopperapproved}();'; js.type = "text/javascript"; document.getElementsByTagName("head")[0].appendChild(js);var link = document.createElement('link');link.rel = 'stylesheet';link.type = 'text/css';link.href = "//www.shopperapproved.com/seal/default.css";document.getElementsByTagName('head')[0].appendChild(link);})();`,
                }} />
              </div>
            </div>
          </Column>
          <Column sm={12} md={2}>
            <div className={`${s['col']} ${s['social-col']}`}>
              <div className={s.social}>
                <ExternalLink
                  href="https://www.facebook.com/people/TS-Pool-Supply/100089723913825/"
                  target="_blank"
                  className={s['social-link']}
                >
                  <div className={s['icon-wrapper']}>
                    <Facebook width="24" />
                  </div>
                </ExternalLink>
              </div>
              <div className={s.social}>
                <ExternalLink
                  href="https://www.google.com/search?q=ts+pool+supply&rlz=1C1GCEA_enUS1030US1030&ei=-lUwZOOvJrfHkPIPqoyC8AM&ved=0ahUKEwjjlL7fqJj-AhW3I0QIHSqGAD4Q4dUDCBA&uact=5&oq=ts+pool+supply&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIECAAQRzIECAAQRzIECAAQRzIECAAQRzIECAAQRzIECAAQRzIECAAQRzIECAAQR0oECEEYAFAAWABg3AhoAHACeACAAQCIAQCSAQCYAQDIAQjAAQE&sclient=gws-wiz-serp#lrd=0x872b132e62ac54f3:0xd143315836aabd9d,1"
                  target="_blank"
                  className={s['social-link']}
                >
                  <div className={s['icon-wrapper']}>
                    <Google width="24" />
                  </div>
                </ExternalLink>
              </div>
            </div>
          </Column>
          <Column sm={12} md={4}>
            <div className={s['col']}>
              <div className={s.email}>
                <div className={s['icon-wrapper']}>
                  <Envelope width="16" />
                </div>
                <ExternalLink
                  href="mailto:onlinesales@tspoolsupply.com?subject=Sale & Product Questions"
                  className={s['email-link']}
                >
                  <strong>Sale & Product Questions</strong> onlinesales@tspoolsupply.com
                </ExternalLink>
              </div>
              <div className={s.email}>
                <div className={s['icon-wrapper']}>
                  <Envelope width="16" />
                </div>
                <ExternalLink
                  href="mailto:info@tspoolsupply.com?subject=Post Sale Questions"
                  className={s['email-link']}
                >
                  <strong>Post Sale Questions Only</strong> info@tspoolsupply.com
                </ExternalLink>
              </div>
              <div className={s.address}>
                <div className={s['icon-wrapper']}>
                  <Address width="16" />
                </div>
                <ExternalLink
                  href="https://maps.app.goo.gl/T3LLj4m9xWMi4e1W8"
                  target="_blank"
                  className={s['address-link']}
                >
                  <strong>Corporate Office & Will-Call</strong> <br />
                  3750 W Indian School Rd <br />
                  Phoenix, AZ 85019 <br />
                  M-F&nbsp;&nbsp;7 a.m. - 11 a.m. & 12 p.m. - 2 p.m.
                </ExternalLink>
              </div>

              <div className={s.address}>
                <div className={s['icon-wrapper']}>
                  <Address width="16" />
                </div>
                <ExternalLink
                  href="https://maps.app.goo.gl/ZGwgEaJvxFxQQHBXA"
                  target="_blank"
                  className={s['address-link']}
                >
                  <strong>Mesa Will-Call</strong> <br />
                  2653 S Alma School Rd <br />
                  Mesa, AZ 85210 <br />
                  M-F&nbsp;&nbsp;7:30 a.m. - 9:30 a.m. &  2 p.m. - 4 p.m.
                </ExternalLink>
              </div>

              <div className={s.address}>
                <div className={s['icon-wrapper']}>
                  <Address width="16" />
                </div>
                <ExternalLink
                  href="https://maps.app.goo.gl/TrCKzAL4pJBr2jfT7"
                  target="_blank"
                  className={s['address-link']}
                >
                  <strong>Surprise Will-Call</strong> <br />
                  15278 W Bell Rd <br />
                  Surprise, AZ 85374 <br />
                  M-F&nbsp;&nbsp;7:30 a.m. - 9:30 a.m. &  2 p.m. - 4 p.m.
                </ExternalLink>
              </div>
            </div>
          </Column>
          <Column sm={12} md={4}>
            <div className={s['col']}>
              <div className={s.roc}>
                <div className={s['icon-wrapper']}>
                  <Hashtag width="16" />
                </div>
                <span>ROC#:</span>
              </div>
              <div className={s['roc-numbers']}>
                ROC076624, ROC080052,
                <br />
                ROC144066, ROC028410
              </div>
            </div>
          </Column>
        </Grid>
        <div className={s.disclaimer}>
          *Some product exclusions apply. Promo applies to select items & freight products not included. Price match for retail customers only. Limited availability.
        </div>
      </section>
      <section className={s['bot-footer']}>
        <div className={s['nav']}>
          <FooterLinks links={links} />
        </div>
      </section>
    </footer>
  )
}

export default Footer
