import { FC, useState } from 'react'
import s from './HamMenu.module.scss'
import DownArrow from '../../../icons/DownArrow'
import { Searchbar } from '@components/common'
import { useLockBodyScroll } from '@hooks'
import { NavLinksProps, SubMenuProps, MenuProps } from '../Navbar'
import { MenuLink } from '@common/types/menu'
import useCustomer from '@framework/customer/use-customer'
import { Link } from '@components/ui'

const SubMenu = ({ subMenuLinks }: SubMenuProps) => {
  return (
    <div className={s['sub-menu-wrap']}>
      {subMenuLinks.map((link: MenuLink) => (
        <Link
          key={`${link.text}sub menu`}
          href={link.href}
          className={s['sub-nav-link']}
        >
          {link.text}
        </Link>
      ))}
    </div>
  )
}

const NavLink: FC<{ link: MenuLink }> = ({ link }) => {
  return (
    <div className={s['mobile-nav-link-wrap']}>
      <Link key={link.text} href={link.href} className={s['mobile-nav-link']}>
        {link.text}
      </Link>
    </div>
  )
}

const NavLinkWithSub: FC<{ link: MenuLink }> = ({ link }) => {
  const [open, setOpen] = useState(false)

  return (
    <div
      key={link.text}
      onClick={() => {
        setOpen(!open)
      }}
    >
      <div className={s['mobile-nav-link-wrap']}>
        <div className={s['mobile-nav-link']}>
          {link.text}{' '}
          <DownArrow
            className={`${s['mobile-nav-link-icon']} ${
              !open ? s['rotate-arrow'] : ''
            }`}
          />
        </div>
        <div className={`${!open ? s.hidden : ''}`}>
          {link.links && <SubMenu subMenuLinks={link.links} />}
        </div>
      </div>
    </div>
  )
}

const MobileNavLinks = ({ links }: NavLinksProps) => {
  const { data } = useCustomer()
  const loggedIn = data?.loggedIn ?? false

  return (
    <>
      {links.map((link: MenuLink, i: number) => {
        if (link.links) {
          return <NavLinkWithSub key={i} link={link} />
        }

        return <NavLink key={i} link={link} />
      })}
      {loggedIn ? (
        <NavLink
          link={{
            text: 'MY ACCOUNT',
            href: 'https://shop.tspoolsupply.com/account',
          }}
        />
      ) : (
        <NavLink
          link={{
            text: 'LOGIN',
            href: 'https://shop.tspoolsupply.com/account/login',
          }}
        />
      )}
    </>
  )
}

const Burger = ({ open, setOpen }: any) => {
  return (
    <div
      className={`${s.burger} ${open ? s['burger-open'] : s['burger-close']}`}
      onClick={() => setOpen(!open)}
    >
      <div />
      <div />
      <div />
    </div>
  )
}

const Menu = ({ size, links, open }: MenuProps) => {
  useLockBodyScroll()

  return (
    <section
      id="hamMenu"
      className={`${s['mobile-menu']} ${open ? s['mobile-menu-open'] : ''}`}
    >
      {size.width <= 1200 && (
        <div className={s['searchbar-wrap']}>
          <Searchbar className={s['searchbar']} />
        </div>
      )}
      <div className={s['links-wrap']}>
        <MobileNavLinks links={links} />
      </div>
    </section>
  )
}

const HamMenu = ({ size, links, open, setOpen }: MenuProps) => {
  return (
    <>
      <Burger open={open} setOpen={setOpen} />
      {open && <Menu size={size} links={links} open={open} setOpen={setOpen} />}
    </>
  )
}

export default HamMenu
