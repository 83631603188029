import React, {
  FC,
  useState,
  useEffect,
  useCallback,
  Children,
  isValidElement,
} from 'react'
import useEmblaCarousel, { EmblaPluginType } from 'embla-carousel-react'
import Autoplay, {
  AutoplayType,
  AutoplayOptionsType,
} from 'embla-carousel-autoplay'
import cn from 'classnames'
import s from './Slider.module.scss'
import { Grid, Column } from '@components/common'

interface Props {
  children: React.ReactNode[] | React.ReactNode
  columns?: number
  navDots?: boolean
  autoplay?: boolean
}

const Slider: FC<Props> = ({
  children,
  columns = 3,
  navDots = true,
  autoplay = false,
}) => {
  let emblaPlugins = [] as EmblaPluginType[]

  if (autoplay) {
    emblaPlugins.push(Autoplay() as EmblaPluginType & AutoplayType)
  }

  const [emblaRef, embla] = useEmblaCarousel(
    {
      loop: true,
      slidesToScroll: columns,
      align: 'start',
      dragFree: false,
    },
    emblaPlugins
  )
  const [selectedIndex, setSelectedIndex] = useState<number>(0)
  const [scrollSnaps, setScrollSnaps] = useState<number[]>([])

  const scrollTo = useCallback(
    (index) => {
      if (!embla) return

      embla.scrollTo(index)
    },
    [embla]
  )

  const onSelect = useCallback(() => {
    if (!embla) return

    setSelectedIndex(embla.selectedScrollSnap())
  }, [embla, setSelectedIndex])

  useEffect(() => {
    if (!embla) return

    onSelect()
    setScrollSnaps(embla.scrollSnapList())
    embla.on('select', onSelect)
  }, [embla, setScrollSnaps, onSelect, columns])

  return (
    <div className={s.root}>
      <div className={s.viewport} ref={emblaRef}>
        <Grid wrap="nowrap">
          {Children.map(children, (sliderItem) => {
            if (isValidElement(sliderItem)) {
              return (
                <Column xs={12 / columns} embla={true}>
                  {sliderItem}
                </Column>
              )
            }
          })}
        </Grid>
      </div>
      {navDots && scrollSnaps.length > 1 && (
        <div className={s.nav}>
          {scrollSnaps.map((_, index) => (
            <button
              key={index}
              className={cn(s.dot, index === selectedIndex ? s.selected : '')}
              onClick={() => scrollTo(index)}
            />
          ))}
        </div>
      )}
    </div>
  )
}

export default Slider
