import React, { FC, useEffect, useState } from 'react'
import { CheckoutButton } from '@components/common'
import { ChecklistButton } from '@components/common'
import { DiscountButton } from '@components/common'
import { CompetitorButton } from '@components/common'
import { UnadvertisedButton } from '@components/common'
import s from './Navbar.module.scss'
import cn from 'classnames'
import { Searchbar } from '@components/common'
import { useWindowSize, useDispatchIframePostMessage } from '@hooks'
import HamMenu from './HamMenu'
import useCustomer from '@framework/customer/use-customer'
import useMenu from '@framework/menu/use-menu'
import { Link, Image } from '@components/ui'
import { MenuLink } from '@common/types/menu'

export interface NavLinksProps {
  links: MenuLink[]
}

export interface SubMenuProps {
  subMenuLinks: MenuLink[]
}

export interface MenuProps {
  size: any
  links: MenuLink[]
  open: boolean
  setOpen: any
}

const SubMenu = ({ subMenuLinks }: SubMenuProps) => {
  return (
    <div className={s['sub-menu-wrap']}>
      {subMenuLinks.map((link: MenuLink) => (
        <Link
          key={`${link.text}sub menu`}
          href={link.href}
          className={s['sub-nav-link']}
        >
          {link.text}
        </Link>
      ))}
    </div>
  )
}

const NavLinks = ({ links }: NavLinksProps) => {
  return (
    <>
      {links.map((link: MenuLink, index) => (
        <NavLink key={index} {...link} />
      ))}
    </>
  )
}

const NavLink = ({ text, href, active, links }: MenuLink) => {
  return (
    <Link
      href={href}
      className={cn(
        s['nav-link'],
        active ? s['active'] : '',
        links ? s['link-has-sub'] : ''
      )}
    >
      {text}
      {links && <SubMenu subMenuLinks={links} />}
    </Link>
  )
}

function NavChecklistButton(): JSX.Element {
  if (typeof window !== 'undefined')
    var checklist = String(localStorage.getItem('checklist'))
  else var checklist = ''
  if (checklist !== 'null' && checklist.length > 2) {
    return (
      <>
        <ChecklistButton />
      </>
    )
  } else return <></>
}

function NavDiscountButton(): JSX.Element {
  if (typeof window !== 'undefined')
    var checklist = String(localStorage.getItem('checklistDiscount'))
  else var checklist = ''
  if (checklist !== 'null' && checklist.length > 2) {
    return (
      <>
        <DiscountButton />
      </>
    )
  } else return <></>
}

function NavCompetitorButton(): JSX.Element {
  if (typeof window !== 'undefined')
    var checklist = String(localStorage.getItem('checklistCompetitor'))
  else var checklist = ''
  if (checklist !== 'null' && checklist.length > 2) {
    return (
      <>
        <CompetitorButton />
      </>
    )
  } else return <></>
}

function NavUnadvertisedButton(): JSX.Element {
  if (typeof window !== 'undefined')
    var checklist = String(localStorage.getItem('checklistUnadvertised'))
  else var checklist = ''
  if (checklist !== 'null' && checklist.length > 2) {
    return (
      <>
        <UnadvertisedButton />
      </>
    )
  } else return <></>
}

const Navbar = () => {
  const { data } = useCustomer()
  const { data: menuData } = useMenu()
  const loggedIn = data?.loggedIn ?? false
  const shopMenu = menuData?.find((menu: any) => {
    if (menu.handle === 'shop') {
      return true
    }
  })

  const LINKS_ARRAY: MenuLink[] = [
    {
      text: 'WHOLESALE',
      href: 'https://shop.tspoolsupply.com/pages/wholesale',
      active: false,
    },
    {
      text: 'SHOP',
      href: '/',
      links: shopMenu?.links,
      active: true,
    },
    {
      text: 'CONTACT',
      href: 'https://shop.tspoolsupply.com/pages/contact',
      active: false,
    },
  ]

  const links = LINKS_ARRAY

  const [open, setOpen] = useState(false)
  const size = useWindowSize()
  const [dispatch] = useDispatchIframePostMessage()
  const setMobileMenuOpen = (open: boolean) => {
    setOpen(open)
    dispatch({ event: 'setMobileMenuOpen', payload: open })
  }

  useEffect(() => {
    if (size.width !== undefined) {
      if (size.width >= 1400) {
        setMobileMenuOpen(false)
      }
    }
  }, [size.width])

  return (
    <header id="topNavBar" className={`${s.root}`}>
      <section className={s['nav-container']}>
        <nav className={s.navbar}>
          <Link className={s['nav-logo-wrap']} href="/">
            <Image
              layout="intrinsic"
              width="300"
              height="64"
              className={s['nav-logo']}
              src="/static_images/ts-pool-supply-logo-blue-horiz-shasta.svg"
              alt="TS Pool Supply Logo"
              unoptimized={true}
            />
          </Link>
          {size.width && size.width >= 1200 ? (
            <div className={s['search-wrap-desktop']}>
              <Searchbar />
            </div>
          ) : (
            ''
          )}
          <div className={s['nav-menu-wrap']}>
            <div className={s['nav-menu']}>
              <span className={s['nav-links-wrap']}>
                <NavLinks links={links} />
                {loggedIn ? (
                  <NavLink
                    text="MY ACCOUNT"
                    href="https://shop.tspoolsupply.com/account"
                  />
                ) : (
                  <NavLink
                    text="LOGIN"
                    href="https://shop.tspoolsupply.com/account/login"
                  />
                )}
              </span>
              <span className={s['nav-buttons-wrap']}>
                <CheckoutButton />
                <NavChecklistButton />
                <NavDiscountButton />
                <NavCompetitorButton />
                <NavUnadvertisedButton />
              </span>
            </div>
          </div>
          <HamMenu
            size={size}
            links={links}
            open={open}
            setOpen={setMobileMenuOpen}
          />
        </nav>
      </section>
    </header>
  )
}

export default Navbar
