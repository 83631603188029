import { FC } from 'react'
import { Deal } from '@components/icons'
import { useUI } from '@components/ui/context'
import s from './UnadvertisedButton.module.scss'

const UnadvertisedButton: FC = () => {
  const { toggleUnadvertised } = useUI()

  const handleUnadvertisedButton = () => {
    toggleUnadvertised()
  }

  const calcItemsCount = () => {
    var checkitemarray = []
    var itemlist = localStorage.getItem('checklistUnadvertised')
    if (itemlist) {
      var itemarray = itemlist.split('~')
      for (var i = 0; i < itemarray.length; i++) {
        if (itemarray[i].length > 2) {
          checkitemarray.push(itemarray[i])
        }
      }
    }
    return checkitemarray.length
  }
  var itemsCount = calcItemsCount()

  return (
    <div onClick={handleUnadvertisedButton} className={s['btn-wrap']} title="Unadvertised Deal">
      <div className={s.btn}>
        <div className={s['cart-count-wrapper']}>
          <Deal />
          {itemsCount > 0 && (
            <span
              className={`${s['cart-count']} ${
                itemsCount > 99 ? ['cart-count-above-hundred'] : ''
              }`}
            >
              {itemsCount}
            </span>
          )}
        </div>
      </div>
    </div>
  )
}

export default UnadvertisedButton
