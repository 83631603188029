import { default as NextImage, ImageProps, ImageLoaderProps } from 'next/image'

// Note: this loader is currently only for Shopify CDN images.
const imageLoader = ({ src, width }: ImageLoaderProps) => {
  // Ignore static/local images
  if (src.includes('static_images/')) {
    return src
  }

  const widthModifier = `_${width}x`

  /**
   * Matches the last .[image_extension] in a src string, just in case someone,
   * for example, puts '.jpg' in a file name that's not actually the extension.
   *
   * We want to append the width modifier to the end of the file name.
   */
  const replacementRegex =
    /\.(jpg|jpeg|png|gif|heic|webp)(?!.*(jpg|jpeg|png|gif|heic|webp))/i
  const newSrc = src.replace(replacementRegex, `${widthModifier}.$1`)

  return newSrc
}

const Image = ({ ...props }: ImageProps) => {
  return (
    <NextImage {...props} style={{ color: 'green' }} loader={imageLoader} />
  )
}

export default Image
