import { FC } from 'react'
import cn from 'classnames'
import s from './Pagination.module.scss'

interface Props {
  // TODO: Add Typing
  loadNextPage: any
  hasNextPage: boolean
  loadingNextPage: boolean
}

const Pagination: FC<Props> = ({
  loadNextPage,
  hasNextPage,
  loadingNextPage,
}) => {
  return (
    <>
      {hasNextPage && (
        <div className={cn(s.btn)} onClick={loadNextPage}>
          {loadingNextPage ? 'Loading...' : 'Load More'}
        </div>
      )}
    </>
  )
}

export default Pagination
