import { FC, useState } from 'react'
import s from './CartSidebar.module.scss'
import { Cross } from '@components/icons'
import { useUI } from '@components/ui/context'
import useCart from '@framework/cart/use-cart'
import usePrice from '@common/product/use-price'
import { LineItem } from '@common/types/cart'
import CartItem from '../CartItem'
import { ExternalLink } from '@components/ui'

const CartSidebar: FC = () => {
  const { closeSidebar } = useUI()
  // TODO Look into implementing isLoading behavior
  const { data, isEmpty } = useCart()

  const { price: subTotal } = usePrice(
    data && {
      amount: Number(data.lineItemsSubtotalPrice),
      currencyCode: data.currency.code,
    }
  )
  const { price: taxTotal } = usePrice(
    data && {
      amount: Number(data.totalTax),
      currencyCode: data.currency.code,
    }
  )
  const { price: total } = usePrice(
    data && {
      amount: Number(data.totalPrice),
      currencyCode: data.currency.code,
    }
  )
  /**
   * Shopify does not supply the shipping amount directly,
   * we can infer the shipping price by subtracting the totalTax and the
   * lineItemsSubtotalPrice from the total.
   * This may prove to be a problem once discounts are involved, but we can
   * cross that bridge when we get there.
   */
  const { price: shippingTotal } = usePrice(
    data && {
      amount: Number(
        data.totalPrice - data.totalTax - data.lineItemsSubtotalPrice
      ),
      currencyCode: data.currency.code,
    }
  )
  /**
   * We use the emptyTotal to see if the shipping/tax have been calculated yet.
   * It's not perfect, as if the tax/shipping is $0.00 then we show
   * "calculated at checkout", but these totals only show when a user backs
   * out of checkout anyways, so bit of an edge-case and showing
   * "calculated at checkout" is only a slightly degradated UX
   */
  const { price: emptyTotal } = usePrice(
    data && {
      amount: 0,
      currencyCode: data.currency.code,
    }
  )

  const [itemsOutOfStock, setItemsOutOfStock] = useState(false)

  return (
    <div className={s.root}>
      <header className={s.header}>
        <h2 className={s.title}>YOUR CART</h2>
        <button onClick={closeSidebar} className={s.close}>
          <Cross />
        </button>
      </header>
      <div className={s.wrapper}>
        {isEmpty ? (
          <div className={s['empty-cart']}>Your cart is empty</div>
        ) : (
          <>
            <ul className={s['cart-items']}>
              {data?.lineItems.map((item: LineItem) => (
                <CartItem
                  key={item.id}
                  item={item}
                  currencyCode={data.currency.code}
                  setItemsOutOfStock={setItemsOutOfStock}
                />
              ))}
            </ul>
            <div className={s['totals-wrapper']}>
              <ul className={s.totals}>
                <li className={s.subtotal}>
                  <span>SUBTOTAL</span>
                  <span>{subTotal}</span>
                </li>
                <li className={s.shipping}>
                  <span>SHIPPING</span>
                  <span>
                    {shippingTotal === emptyTotal
                      ? 'Calculated at checkout'
                      : shippingTotal}
                  </span>
                </li>
                <li className={s.tax}>
                  <span>TAX</span>
                  <span>
                    {taxTotal === emptyTotal
                      ? 'Calculated at checkout'
                      : taxTotal}
                  </span>
                </li>
                <li className={s.total}>
                  <span>TOTAL</span>
                  <span>{total}</span>
                </li>
              </ul>
            </div>
            <div className={s['total-disclaimer']}>
              Tax and shipping are calculated during checkout, the total
              displayed here may not reflect the final checkout price.
            </div>
            {itemsOutOfStock && (
              <div className={s['out-of-stock']}>
                You have items in your cart that are no longer available. Your
                cart will be updated to remove these items at checkout
              </div>
            )}
            <ExternalLink className={s.checkout} href="/api/checkout">
              CHECKOUT
            </ExternalLink>
          </>
        )}
      </div>
    </div>
  )
}

export default CartSidebar
