const DownArrow = ({ ...props }) => {
  return (
    <svg
      {...props}
      width="10"
      height="7"
      viewBox="0 0 10 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.148812 1.10225L4.64074 6.81088C4.68792 6.87084 4.74393 6.9184 4.80557 6.95085C4.86721 6.9833 4.93328 7 5 7C5.06672 7 5.13279 6.9833 5.19443 6.95085C5.25607 6.9184 5.31208 6.87084 5.35926 6.81088L9.85119 1.10225C9.92224 1.01195 9.97063 0.896903 9.99024 0.771652C10.0098 0.646401 9.99978 0.516576 9.96132 0.398592C9.92287 0.280608 9.85775 0.179765 9.7742 0.108816C9.69065 0.0378676 9.59242 -5.29742e-07 9.49193 0L0.508069 0C0.407583 -5.29742e-07 0.309353 0.0378676 0.225801 0.108816C0.14225 0.179765 0.077129 0.280608 0.0386745 0.398592C0.000219967 0.516576 -0.00984137 0.646401 0.00976286 0.771652C0.0293671 0.896903 0.0777564 1.01195 0.148812 1.10225Z"
        fill="#1A61A8"
      />
    </svg>
  )
}

export default DownArrow
