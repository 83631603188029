import { FC } from 'react'
import { Link } from '@components/ui'
import s from './Breadcrumb.module.scss'

export interface Crumb {
  title: string
  link?: string
}

interface Props {
  crumbs: Crumb[]
}

const Breadcrumb: FC<Props> = ({ crumbs }) => {
  return (
    <div className={s.breadcrumb}>
      {crumbs &&
        crumbs.map((crumb) =>
          crumb.link ? (
            <Link
              key={crumb.link}
              href={crumb.link}
              className={s['breadcrumb-item']}
            >
              {crumb.title}
            </Link>
          ) : (
            <span key={crumb.title} className={s['breadcrumb-item']}>
              {crumb.title}
            </span>
          )
        )}
    </div>
  )
}

export default Breadcrumb
