const Search = ({ ...props }) => {
  return (
    <svg
      {...props}
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.7426 17.3561L15.1967 13.8469C17.8878 10.4958 17.6345 5.56406 14.5635 2.4975C12.9489 0.885191 10.8277 0 8.54816 0C6.26865 0 4.11578 0.885191 2.50113 2.4975C0.886476 4.10982 0 6.22795 0 8.53577C0 10.8436 0.886476 12.9617 2.50113 14.574C4.11578 16.1547 6.23699 17.0715 8.54816 17.0715C10.4794 17.0715 12.379 16.4077 13.867 15.2063L17.3813 18.7155C17.5712 18.9052 17.7928 19 18.0461 19C18.2994 19 18.5527 18.9052 18.711 18.7155C19.0909 18.3361 19.0909 17.7354 18.7426 17.3561ZM15.1967 8.53577C15.1967 10.3062 14.5002 11.9817 13.2338 13.2146C11.9674 14.4476 10.3211 15.1747 8.54816 15.1747C6.77521 15.1747 5.09724 14.4792 3.8625 13.2146C2.62777 11.9501 1.89959 10.3062 1.89959 8.53577C1.89959 6.76539 2.59611 5.08985 3.8625 3.85691C5.1289 2.62396 6.74355 1.89684 8.54816 1.89684C10.3211 1.89684 11.9991 2.59235 13.2338 3.85691C14.4686 5.12146 15.1967 6.73378 15.1967 8.53577Z"
        fill="#2E6DB0"
      />
    </svg>
  )
}

export default Search
