import React, { FC } from 'react'
import s from './Quantity.module.scss'
import { Plus, Minus } from '@components/icons'

interface Props {
  value: number
  increase: () => any
  decrease: () => any
  handleChange: React.ChangeEventHandler<HTMLInputElement>
  max?: number | undefined | null
  active?: boolean | undefined
}

const Quantity: FC<Props> = ({
  value,
  increase,
  decrease,
  handleChange,
  max = 99,
  active = true,
}) => {
  return (
    <div className={`${s.root} ${!active ? s['inactive'] : 'active'}`}>
      <button
        type="button"
        onClick={decrease}
        className={s.action}
        disabled={value <= 1}
      >
        <Minus width={18} height={18} />
      </button>
      <input
        className={s.input}
        onChange={(e) =>
          Number(e.target.value) < (max ? max : 99) + 1
            ? handleChange(e)
            : () => {}
        }
        value={active ? value : 0}
        type="number"
        max={max ? max : 99}
        min="0"
        readOnly
      />
      <button
        type="button"
        onClick={increase}
        className={s.action}
        disabled={value < 1 || value >= (max ? max : 99)}
      >
        <Plus width={18} height={18} />
      </button>
    </div>
  )
}

export default Quantity
