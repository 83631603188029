import { FC } from 'react'
import { Bag as Cart } from '@components/icons'
import { useUI } from '@components/ui/context'
import useCart from '@framework/cart/use-cart'
import { LineItem } from '@common/types/cart'
import s from './CheckoutButton.module.scss'

const CheckoutButton: FC = () => {
  const { toggleSidebar } = useUI()
  const { data } = useCart()

  const handleCheckoutButton = () => {
    toggleSidebar()
  }

  const itemsCount =
    data?.lineItems.reduce((count: number, item: LineItem) => {
      return count + item.quantity
    }, 0) ?? 0

  return (
    <div onClick={handleCheckoutButton} className={s['btn-wrap']} title="Checkout">
      <div className={s.btn}>
        <div className={s['cart-count-wrapper']}>
          <Cart />
          {itemsCount > 0 && (
            <span
              className={`${s['cart-count']} ${
                itemsCount > 99 ? ['cart-count-above-hundred'] : ''
              }`}
            >
              {itemsCount}
            </span>
          )}
        </div>
      </div>
    </div>
  )
}

export default CheckoutButton
