import { FC } from 'react'
import s from './Hero.module.scss'
import { Link, Image } from '@components/ui'
import { Slider } from '@components/common'

interface Props {
  slides: HeroSlide[]
}

export interface HeroSlide {
  url: string
  title: string
  subtitle?: string
  link?: URL
  buttonText?: string
  mobileUrl?: string
}

const Slide: FC<HeroSlide> = ({ url, title, subtitle, link, buttonText, mobileUrl }) => {
  return (
    <div className={s.root}>
      <div className={s['bg-wrapper']}>
        {link && (
          <Link href={link}>
            <Image
              className={s.bg}
              src={url}
              layout="fill"
              objectFit="cover"
              objectPosition="center top"
            />
            <Image
              className={s['bg-mobile']}
              src={mobileUrl ? mobileUrl : url}
              layout="fill"
              objectFit="cover"
              objectPosition="center top"
            />
          </Link>
        )}
        {!link && (
          <>
            <Image
              className={s.bg}
              src={url}
              layout="fill"
              objectFit="cover"
              objectPosition="center top"
            />
            <Image
              className={s['bg-mobile']}
              src={mobileUrl ? mobileUrl : url}
              layout="fill"
              objectFit="cover"
              objectPosition="center top"
            />
          </>
        )}
      </div>
      <div className={s['content-wrapper']}>
        {title.length > 1 && (
          <>
            <h1 className={s.title}>{title}</h1>
            <h2 className={s.subtitle}>{subtitle}</h2>
            {link && (
              <Link href={link} className={s.button}>
                {buttonText || 'SHOP'}
              </Link>
            )}
          </>
        )}
      </div>
    </div>
  )
}

const Hero: FC<Props> = ({ slides }) => {
  return (
    <Slider columns={1} navDots={false} autoplay={true}>
      {slides.map((slide, i) => (
        <Slide key={i} {...slide} />
      ))}
    </Slider>
  )
}

export default Hero
