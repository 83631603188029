import { FC, useEffect, useRef, useState } from 'react'
import s from './Competitor.module.scss'
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import { useDispatchIframePostMessage } from '@hooks'
import { Cross, Trash } from '@components/icons'
import { useUI } from '@components/ui/context'
import emailjs from 'emailjs-com'

const Competitor: FC = () => {
  const CompetitorRef = useRef() as React.MutableRefObject<HTMLDivElement>
  const contentRef = useRef() as React.MutableRefObject<HTMLDivElement>
  const [dispatch] = useDispatchIframePostMessage()
  const { closeCompetitor } = useUI()
  const { openCompetitor } = useUI()
  const { openChecklistSuccess } = useUI()
  const { closeChecklistSuccess } = useUI()

  const onKeyDownCompetitor = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.code === 'Escape') {
      closeCompetitor()
      closeChecklistSuccess()
    }
  }

  useEffect(() => {
    if (CompetitorRef.current) {
      CompetitorRef.current.focus()
    }

    const contentElement = contentRef.current

    if (contentElement) {
      disableBodyScroll(contentElement, { reserveScrollBarGap: true })
    }

    dispatch({
      event: 'setCompetitorOpen',
      payload: true,
    })

    return () => {
      clearAllBodyScrollLocks()

      dispatch({
        event: 'setCompetitorOpen',
        payload: false,
      })
    }
  }, [])

  const checklistText = 'No items in your list.'

  const itemlist = localStorage.getItem('checklistCompetitor')
  var emaillist = ''
  if (itemlist) {
    var itemarray = itemlist.split('~')
    var checkitemarray = []
    for (var i = 0; i < itemarray.length; i++) {
      if (itemarray[i].length > 2) {
        emaillist += itemarray[i] + ' \n'
        checkitemarray.push(itemarray[i])
      }
    }
  }

  const removeFromCompetitor = async (
    event: React.MouseEvent<HTMLElement>,
    productName: string
  ) => {
    event.preventDefault()
    var newitemlist = String(localStorage.getItem('checklistCompetitor'))
    newitemlist = newitemlist?.replace(productName, '')
    newitemlist = newitemlist?.replace(/~+/g, '~')
    localStorage.setItem('checklistCompetitor', newitemlist)
    closeCompetitor()
    openCompetitor()
    try {
    } catch (error) {
      throw error
    }
  }

  const sendCompetitorEmail = async (
    event: React.SyntheticEvent<HTMLFormElement>
  ) => {
    event.preventDefault()
    emailjs
      .sendForm(
        'service_stbrxto', // service ID
        'template_hepftbs', // template ID for Beat Competitor's Price
        event.currentTarget, //templateParams
        'TLmbzSmXJ0cTx_TA0' // public key
      )
      .then(
        (result) => {
          localStorage.removeItem('checklistCompetitor')
          closeCompetitor()
          openChecklistSuccess()
          console.log('Email Sent')
        },
        (error) => {
          console.log(error.text)
        }
      )
  }

  return (
    <div
      className={s.root}
      ref={CompetitorRef}
      onKeyDown={onKeyDownCompetitor}
      tabIndex={1}
    >
      <div>
        <div className={s.backdrop} onClick={closeCompetitor} />
        <section className={s['sidebar-wrapper']}>
          <div className={s.sidebar}>
            <header className={s.header}>
              <h2 className={s.title}>BEAT COMPETITOR'S PRICE*</h2>
              <button onClick={closeCompetitor} className={s.close}>
                <Cross />
              </button>
            </header>
            <div className={s.wrapper}>
              {itemlist ? (
                checkitemarray && checkitemarray.length ? (
                  <>
                    <ul className={s['cartitems']}>
                      {checkitemarray.map(function (itemname, index) {
                        return (
                          <li key={index}>
                            <span>{itemname}</span>
                            <button
                              className={s.remove}
                              onClick={(event) =>
                                removeFromCompetitor(event, itemname)
                              }
                            >
                              <Trash width={20} height={20} />
                            </button>
                          </li>
                        )
                      })}
                    </ul>
                    <p>Please complete the form & our team will send a quote to the email address provided. Please also check your spam folder for emails from our team.</p>
                    <form
                      className={s.contactform}
                      onSubmit={sendCompetitorEmail}
                    >
                      <label htmlFor="from_name">Full Name (Required)</label>
                      <input
                        className={s.input}
                        placeholder="Full Name *"
                        type="name"
                        id="from_name"
                        name="from_name"
                        required
                      />
                      <label htmlFor="from_email">Email (Required)</label>
                      <input
                        className={s.input}
                        placeholder="Email *"
                        type="email"
                        id="from_email"
                        name="from_email"
                        required
                      />
                      <label htmlFor="customer_state">State (Required)</label>
                      <select
                        className={s.input}
                        id="customer_state"
                        name="customer_state"
                        required
                      >
                        <option value="">State *</option>
                        <optgroup>
                          <option>Alabama</option>
                          <option>Alaska</option>
                          <option>Arizona</option>
                          <option>Arkansas</option>
                          <option>California</option>
                          <option>Colorado</option>
                          <option>Connecticut</option>
                          <option>Delaware</option>
                          <option>District Of Columbia</option>
                          <option>Florida</option>
                          <option>Georgia</option>
                          <option>Hawaii</option>
                          <option>Idaho</option>
                          <option>Illinois</option>
                          <option>Indiana</option>
                          <option>Iowa</option>
                          <option>Kansas</option>
                          <option>Kentucky</option>
                          <option>Louisiana</option>
                          <option>Maine</option>
                          <option>Maryland</option>
                          <option>Massachusetts</option>
                          <option>Michigan</option>
                          <option>Minnesota</option>
                          <option>Mississippi</option>
                          <option>Missouri</option>
                          <option>Montana</option>
                          <option>Nebraska</option>
                          <option>Nevada</option>
                          <option>New Hampshire</option>
                          <option>New Jersey</option>
                          <option>New Mexico</option>
                          <option>New York</option>
                          <option>North Carolina</option>
                          <option>North Dakota</option>
                          <option>Ohio</option>
                          <option>Oklahoma</option>
                          <option>Oregon</option>
                          <option>Pennsylvania</option>
                          <option>Rhode Island</option>
                          <option>South Carolina</option>
                          <option>South Dakota</option>
                          <option>Tennessee</option>
                          <option>Texas</option>
                          <option>Utah</option>
                          <option>Vermont</option>
                          <option>Virginia</option>
                          <option>Washington</option>
                          <option>West Virginia</option>
                          <option>Wisconsin</option>
                          <option>Wyoming</option>
                        </optgroup>
                      </select>
                      <label htmlFor="lower_website">
                        Enter Website With Lower Price
                      </label>
                      <input
                        className={s.input}
                        id="lower_website"
                        name="lower_website"
                        placeholder="Enter Website With Lower Price"
                        required
                      />
                      <label htmlFor="referrer">
                        How did you hear about us?
                      </label>
                      <select className={s.input} id="referrer" name="referrer">
                        <option value="No Response">
                          How did you hear about us?
                        </option>
                        <optgroup>
                          <option>Google</option>
                          <option>Shasta Pools</option>
                          <option>Facebook</option>
                          <option>Friends and Family</option>
                          <option>Email</option>
                          <option>Home and Garden Show</option>
                        </optgroup>
                      </select>
                      <input
                        type="hidden"
                        name="product_list"
                        value={emaillist}
                      />
                      <input
                        className={s.checkout}
                        type="submit"
                        value="Send Price Request"
                      />
                    </form>
                    {/*<button onClick={closeCompetitor} className={s.addmore}>
                      Add More Items
                    </button>*/}
                  </>
                ) : (
                  <>
                    <div className={s.noitems}>{checklistText}</div>
                  </>
                )
              ) : (
                <>
                  <div className={s.noitems}>{checklistText}</div>
                </>
              )}
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

export default Competitor
