import { ReactNode, FC, ComponentType, HTMLAttributes } from 'react'
import s from './Container.module.scss'

interface Props {
  children: ReactNode | ReactNode[]
  el?: ComponentType<HTMLAttributes<HTMLElement>>
}

const Container: FC<Props> = ({ children, el: Component = 'div' }) => {
  return <Component className={s.root}>{children}</Component>
}

export default Container
