import {
  FC,
  JSXElementConstructor,
  ReactElement,
  ReactNodeArray,
  ReactPortal,
  useEffect,
  useRef,
  useState,
} from 'react'

import s from './ScrollToTop.module.scss'
import DownArrow from '@components/icons/DownArrow'

//TS hack to prevent a build error with types
const smoothscroll = require('smoothscroll-polyfill')

function scrollToTop() {
  // kick off the polyfill!
  smoothscroll.polyfill()

  document.documentElement.scrollTo({ top: 0, behavior: 'smooth' }) // For Chrome, Firefox, IE and Opera
}

const ScrollToTop: FC = () => {
  return (
    <section className={s.root}>
      <div onClick={scrollToTop} className={s['scroll-to-top-wrap']}>
        <DownArrow />
      </div>
    </section>
  )
}

export default ScrollToTop
